
import { defineComponent } from 'vue';
import { ContentController } from '@/controller';

export default defineComponent({
    name: "About",
    components: { },
    data(){
        return{
            content: String,
        }
    },
    computed:{

    },
    created(){
        console.log('about created');
        this.fetchData();

    }, 
    methods:{
        async fetchData(){
            this.content = await ContentController.getContent(ContentController.ABOUT_URI)
        }
    }
})
